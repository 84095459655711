<template>
  <div class="">
    <TopicForm ref="form" action="Update" v-model="formData" @formSubmitted="updateTopic" />
  </div>
</template>
  
  <script>
import TopicForm from "@/views/components/topic/TopicForm";
import TopicService from "@/services/TopicService";

export default {
  name: "updateTopic",
  components: {
    TopicForm
  },
  data() {
    return {
      topicService: new TopicService(),
      id: this.$route.fullPath.split('/').pop(),
      formData: {
      },
    }
  },
  methods: {
    updateTopic(e) {
      console.log(e);
      this.$refs.form.loading = true;
      this.topicService.update(this.formData._id, e).then(res => {
        this.$router.push('/topics')
      }).catch(err => {
        console.log(err);
       }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
    mappingData(data) {
      return {
        ...data,
       }
    },
    getTopic() {
      this.topicService.show(this.id).then(res => {
        this.formData = this.mappingData(res);
        this.formData.topics = this.formData.topics.map(e => e._id);
        this.formData.languages = this.formData.languages.map(e => e._id);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getTopic()
  }
}
</script>
  
  <style scoped>
  </style>
  